/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useState } from 'react';
import './tickets.css';
import * as TicketsSDK1 from 'tickets-sdk';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import reiniciar from './../img/reiniciar.svg';
import dots from './../img/dots.svg';
import cancel from './../img/cancel.svg';
import lixo from './../img/lixo.svg';
import cherry from './../img/cherry-no-comments.png';
import languages from './languages.json';
import { connectSocket, listenQueueCallBack } from './api';
import { handleRemove, getDateFromId, sendNotification } from './utils';

const TicketsSDK = TicketsSDK1.default.TicketsSDK;
const serverUrl = process.env.REACT_APP_SERVERURL;
const ticketSdk = new TicketsSDK(serverUrl);

class OneTicket extends Component {
  constructor(props) {
    super(props);

    this.state = { ticket: {}, queue: {}, average: 0, showComponent: false, table: '', lastTicket: {} };
    this.Example = this.Example.bind(this);
    this.handleData = this.handleData.bind(this);
  }

  _onButtonClick(op) {
    this.setState({
      showComponent: op
    });
  }

  async componentDidMount() {
    await connectSocket(serverUrl);
    const ticket = this.props.entrie;
    const queue = await ticketSdk.Queue.getOneById(ticket.queueId);
    this.setState({ ticket, queue });
    if (queue._id) {
      listenQueueCallBack(queue._id, this.handleData);
    }
    if (ticket._id) {
      if (ticket.position > queue.currentQueuePosition) {
        const average = await ticketSdk.Tickets.getAverage(ticket._id);
        const lastTicket = await this.getLastValidated(queue._id);
        this.setState({ average: average.status === 200 ? average.data : null, lastTicket });
      }
    }
  }

  async handleData(data) {
    const updatedQueue = await ticketSdk.Queue.getOneById(data.QueueId);
    const lastTicket = await this.getLastValidated(updatedQueue._id);
    const resetAverage = await ticketSdk.Tickets.getAverage(this.state.ticket._id);

    this.setState({ queue: updatedQueue, lastTicket, average: resetAverage.status === 200 ? resetAverage.data : null });

    if (updatedQueue.currentQueuePosition === this.state.ticket.position) {
      sendNotification(updatedQueue.name);
    }
  }

  async getLastValidated(queueId) {
    const data = await ticketSdk.Tickets.getLastValidated(queueId);
    if (data.code === 204) {
      return undefined;
    }
    return data.data;
  }

  async handleCancel(ticketId) {
    await ticketSdk.Tickets.cancel(ticketId);
    const ticket = await ticketSdk.Tickets.getOneById(ticketId);
    this.setState({ ticket });
  }

  Example() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const queue = this.state.queue;
    const ticket = this.state.ticket;
    const idioma = this.props.entry;

    return (
      <>
        <img alt="" src={dots} className="Shape" onClick={handleShow} />

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title>
              {queue.name} - {ticket.position}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-center">
              <Button
                onClick={() => {
                  this.handleCancel(ticket._id);
                  handleClose();
                }}
              >
                <img alt="" src={reiniciar} className="reiniciar" />
                {languages['cancela'][idioma]}
              </Button>
            </div>
            <div className="modal-center2">
              <Button onClick={handleShow2}>
                <img alt="" src={lixo} className="reiniciar" />
                {languages['remover'][idioma]}
              </Button>
            </div>
            <div className="modal-footer2">
              <Button onClick={handleClose}>
                <img alt="" src={cancel} className="reiniciar" />
                {languages['cancelar'][idioma]}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show2}
          onHide={handleClose2}
          size="lg"
          dialogClassName="modal-content2"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <img alt="" src={cherry} className="cherry-no-comments" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-question">
              <Modal.Title>{languages['pergunta'][idioma]}</Modal.Title>
            </div>
            <div className="modal-final">
              <Button className="btn-cancel" onClick={handleClose2}>
                {languages['cancela'][idioma]}
              </Button>
              <Button className="btn-close" onClick={() => handleRemove(ticket._id)}>
                {languages['remover'][idioma]}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    const ticket = this.state.ticket;
    const queue = this.state.queue;
    const average = this.state.average;
    const idioma = this.props.entry;
    const lastTicket = this.state.lastTicket;

    let datestring;
    let estimatedDate;
    let remaining;
    if (ticket._id) {
      datestring = getDateFromId(ticket._id);

      remaining = ticket.position - queue.currentQueuePosition;
      remaining = remaining.toString();

      let newDate;
      if (lastTicket.validatedTime) {
        newDate = new Date(lastTicket.validatedTime);
      } else {
        newDate = new Date(parseInt(ticket._id.substring(0, 8), 16) * 1000);
      }

      if (ticket.position > queue.currentQueuePosition && average != null) {
        newDate.setTime(newDate.getTime() + average * parseInt(remaining));
        estimatedDate = ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2);
      } else {
        estimatedDate = 'Indisponível';
      }
    }

    return (
      <div key={ticket._id} className="queueData">
        <div className="queueData-title">
          {queue.local}
          <this.Example />
        </div>
        <div className="queueData-middle">{queue.name}</div>
        <div className="queueData-button">
          <div className="queueData-button-middle">
            <div className="queueData-button-middle-text">
              <span className="span1">{ticket.position}</span>
            </div>
          </div>
        </div>
        <div className="queueData-middle2">
          {languages['obtida'][idioma]} {datestring}
        </div>

        {ticket.state === 'Canceled' ? (
          <div className="queueData-bottom">
            <div className="ticket-finished">{languages['cancelado'][idioma]}</div>
          </div>
        ) : /* eslint-disable-next-line */
        remaining != 0 ? (
          remaining > 0 ? (
            <div className="queueData-bottom">
              <div className="queueData-bottom-left">
                <div className="queueData-bottom-up">{remaining}</div>
                <div className="queueData-bottom-bottom">{languages['emAtendimento'][idioma]}</div>
              </div>
              <div className="queueData-bottom-right">
                <div className="queueData-bottom-up">{estimatedDate}</div>
                <div className="queueData-bottom-bottom">{languages['esperaUltima'][idioma]}</div>
              </div>
            </div>
          ) : (
            <div className="queueData-bottom">
              <div className="ticket-finished">{languages['atendida'][idioma]}</div>
            </div>
          )
        ) : (
          <div className="queueData-bottom">
            <div className="ticket-yourTurn">{languages['suaVez'][idioma]}</div>
          </div>
        )}
      </div>
    );
  }
}

export default OneTicket;
