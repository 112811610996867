import languages from './languages.json';

async function handleRemove(ticketId) {
  const size = localStorage.getItem('@my-tickets-pwa/tickets-size');
  for (let i = 1; i <= size; i++) {
    let id = localStorage.getItem('@my-tickets-pwa/ticketId/' + i);
    if (id === ticketId) {
      for (let j = i; j <= size - 1; j++) {
        let b = j + 1;
        let id2 = localStorage.getItem('@my-tickets-pwa/ticketId/' + b);
        localStorage.setItem('@my-tickets-pwa/ticketId/' + j, id2);
      }
      localStorage.removeItem('@my-tickets-pwa/ticketId/' + size);
      let newSize = parseInt(size) - 1;
      localStorage.setItem('@my-tickets-pwa/tickets-size', newSize);
      window.location.reload(false);
      return true;
    }
  }
  return false;
}

function canAddTicket(ticketId) {
  const size = localStorage.getItem('@my-tickets-pwa/tickets-size');
  for (let i = 1; i <= size; i++) {
    let id = localStorage.getItem('@my-tickets-pwa/ticketId/' + i);
    if (id === ticketId) {
      return false;
    }
  }
  return true;
}

async function handleAddTicket(ticketId) {
  if (!(ticketId === undefined)) {
    const size = localStorage.getItem('@my-tickets-pwa/tickets-size');

    if (size === null) {
      localStorage.setItem('@my-tickets-pwa/tickets-size', 1);
      localStorage.setItem('@my-tickets-pwa/ticketId/1', ticketId);
    } else {
      if (canAddTicket(ticketId)) {
        let newSize = parseInt(size) + 1;

        localStorage.setItem('@my-tickets-pwa/tickets-size', newSize);
        localStorage.setItem('@my-tickets-pwa/ticketId/' + newSize, ticketId);
      }
    }
    window.location.href = '/';
  }
}

function getDateFromId(id) {
  const d = new Date(parseInt(id.substring(0, 8), 16) * 1000);
  const datestring =
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2) +
    ' ' +
    ('0' + d.getDate()).slice(-2) +
    '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' +
    d.getFullYear();
  return datestring;
}

async function sendNotification(title) {
  const idioma = localStorage.getItem('@tickets-pwa/language');
  const text = languages['suaVez'][idioma ? idioma : 'pt'];
  navigator.serviceWorker.ready.then(function(registration) {
    registration.showNotification(title, {
      body: text,
      icon: './192.png',
      vibrate: [500, 100, 300],
      tag: 'tickets'
    });
  });
}

export { handleRemove, handleAddTicket, getDateFromId, sendNotification };
