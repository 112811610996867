import React, { Component } from 'react';
import './tickets.css';
import OneTicket from './OneTicket';

class TicketMapper extends Component {
  constructor(props) {
    super(props);

    this.state = { tickets: [] };
  }

  createTickets = ticket => {
    const idioma = this.props.entry;
    return <OneTicket entrie={ticket} key={ticket._id} entry={idioma} />;
  };

  render() {
    const tickets = this.props.entries;
    const listItems = tickets.map(this.createTickets);

    return <div className="theList">{listItems}</div>;
  }
}

export default TicketMapper;
