import openSocket from 'socket.io-client';

let socket;

async function connectSocket(server, room) {
  const url = server;
  // socket = openSocket(url + '?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9');
  socket = openSocket(url);

  socket.on('disconnect', () => {
    console.log('disconnect close');
  });
}

function listenQueueCallBack(queueId, callback) {
  socket.on('queueId/' + queueId, callback);
}

export { connectSocket, listenQueueCallBack };
