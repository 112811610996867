/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tickets.css';
import cherry from './../img/cherry-welcome.png';
import cherry46 from './../img/cherry-46.png';
import qr from './../img/white-qr.svg';
import path from './../img/path.svg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QrCamera from './QrCamera';
import languages from './languages.json';

class AddTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      result: 'No result',
      name: '',
      alerted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateAlertState = this.updateAlertState.bind(this);
    this.Example = this.Example.bind(this);
  }

  detectWebcam(callback) {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then(devices => {
      callback(devices.some(device => 'videoinput' === device.kind));
    });
  }

  async componentDidMount() {
    this.detectWebcam(hasWebcam => {
      this.setState({ hasWebcam: hasWebcam ? true : false });
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  navigate() {
    window.location.href = '/';
  }

  updateAlertState(op) {
    this.setState({
      alerted: op
    });
  }

  Example(entry) {
    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAlert = () => setAlert(true);

    const handleToUpdate = someArg => {
      handleClose();
      handleAlert();
      this.updateAlertState(true);
    };
    return (
      <>
        {alert ? (
          <Button onClick={handleShow} className="btn-reler">
            <img alt="" src={qr} className="reiniciar" />
            {languages['lerNovamente'][entry.entry]}
          </Button>
        ) : (
          <Button onClick={handleShow}>
            <img alt="" src={qr} className="reiniciar" />
            {languages['ler'][entry.entry]}
          </Button>
        )}

        <Modal
          dialogClassName="modal-content2"
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <QrCamera entry={entry.entry} handleToUpdate={handleToUpdate} />
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    const search = this.props.location.search; // returns the URL query String
    const params = new URLSearchParams(search);
    const idioma = params.get('lang');
    let hasWebcam = this.state.hasWebcam;
    const alerted = this.state.alerted;

    return (
      <div className="">
        <section className="results--section">
          <div className="container">
            <div className="queue--section container">
              <img alt="" src={path} className="Path2" onClick={() => this.navigate()} />
              <div className="clown">
                {alerted ? (
                  <img alt="" src={cherry46} className="cherry-welcome" />
                ) : (
                  <img alt="" src={cherry} className="cherry-welcome" />
                )}
              </div>
              {alerted ? (
                <div className="add-title">{languages['erroCodigo'][idioma]}</div>
              ) : (
                <div className="add-title">{languages['adicionar'][idioma]}</div>
              )}

              {hasWebcam ? (
                <div>
                  <div className="add-QR">
                    <this.Example entry={idioma} />
                  </div>
                  <div className="add-qr">{languages['frase2'][idioma]}</div>
                </div>
              ) : (
                <div className="add-QR">
                  <div className="add-qr">{languages['utilizeCamera'][idioma]}</div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default AddTicket;
