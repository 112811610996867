import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AddTicket from './Tickets/addTicket';

const requestNotificationPermission = async () => {
  await window.Notification.requestPermission();
};

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/ticket/:ticketId" component={App} />
      <Route path="/add" component={AddTicket} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

serviceWorker.register();
requestNotificationPermission();
