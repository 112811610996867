import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Tickets from './Tickets/Tickets';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const ticketId = this.props.match.params.ticketId;
    return (
      <div className="">
        <section className="results--section">
          <div className="container">
            <Tickets newTicket={ticketId} />
          </div>
          <div className="results--section__inner"></div>
        </section>
      </div>
    );
  }
}

export default App;
