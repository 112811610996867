/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useState } from 'react';
import * as TicketsSDK1 from 'tickets-sdk';
import TicketMapper from './TicketMapper';
import add from './../img/add.svg';
import unitedKingdom from './../img/united-kingdom.svg';
import portugal from './../img/portugal.svg';
import france from './../img/france.svg';
import italy from './../img/italy.svg';
import spain from './../img/spain.svg';
import checked from './../img/checked.svg';
import unChecked from './../img/unchecked.svg';
import settingsSVG from './../img/settings.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import navigate from './../img/navigate.svg';
import language from './../img/language.svg';
import languages from './languages.json';
import { handleRemove, handleAddTicket } from './utils';

const TicketsSDK = TicketsSDK1.default.TicketsSDK;
const serverUrl = process.env.REACT_APP_SERVERURL;
const ticketSdk = new TicketsSDK(serverUrl);

class Tickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tickets: [],
      idioma: ''
    };
    this.ModalSettings = this.ModalSettings.bind(this);
    this.ModalIdioma = this.ModalIdioma.bind(this);
    this.formSubmitIdioma = this.formSubmitIdioma.bind(this);
    this.onValueChangeIdioma = this.onValueChangeIdioma.bind(this);
  }

  async setupWithSDK() {
    const ticketParamsId = this.props.newTicket;
    if (ticketParamsId) {
      try {
        const newTicket = await ticketSdk.Tickets.getOneById(ticketParamsId);
        if (newTicket._id) {
          handleAddTicket(ticketParamsId);
        }
      } catch (e) {
        window.location.href = '/';
      }
    }
    const tickets = [];
    const size = localStorage.getItem('@my-tickets-pwa/tickets-size');
    for (let i = 1; i <= size; i++) {
      let id = localStorage.getItem('@my-tickets-pwa/ticketId/' + i);
      try {
        let newTicket = await ticketSdk.Tickets.getOneByIdV2(id);
        if (newTicket.status === 204) {
          handleRemove(id);
        } else if (newTicket.status === 200) {
          tickets.push(newTicket.data);
        }
      } catch (e) {}
    }
    this.setState({ tickets });

    const defaultLanguage = localStorage.getItem('@tickets-pwa/language');
    this.setState({ idioma: defaultLanguage ? defaultLanguage : 'pt' });
    this.setState({ selectedOptionIdioma: defaultLanguage ? defaultLanguage : 'pt' });
  }

  async componentDidMount() {
    await this.setupWithSDK();
  }

  navigate(lang) {
    window.location.href = '/add?lang=' + lang;
  }

  formSubmitIdioma(event) {
    event.preventDefault();
    this.setState({ idioma: this.state.selectedOptionIdioma });
    localStorage.setItem('@tickets-pwa/language', this.state.selectedOptionIdioma);
  }

  onValueChangeIdioma(event) {
    this.setState({
      selectedOptionIdioma: event.target.value
    });
  }

  ModalIdioma() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const idioma = this.state.idioma;
    const idiomas = ['en', 'pt', 'fr', 'es', 'it'];
    const images = [unitedKingdom, portugal, france, spain, italy];
    const teste = ['Inglês', 'Português', 'Francês', 'Espanhol', 'Italiano'];

    const radio = [];
    for (var i = 0; i < idiomas.length; i++) {
      radio.push(
        <div className="radio2" key={i}>
          <div className="flag">
            <img alt="" src={images[i]} />
          </div>
          <div className="balcao2"> {teste[i]} </div>
          <div className="radio-option">
            <label>
              <input
                type="radio"
                value={idiomas[i]}
                checked={this.state.selectedOptionIdioma === idiomas[i]}
                onChange={this.onValueChangeIdioma}
              />
              {this.state.selectedOptionIdioma === idiomas[i] ? (
                <img alt="" src={checked} />
              ) : (
                <img alt="" src={unChecked} />
              )}
            </label>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="modal-settings-button" onClick={handleShow}>
          <div className="left-img">
            <img alt="" src={language} />
          </div>
          <div className="middle-text">{languages['idioma'][idioma]}</div>
          <div className="right-img">
            <img alt="" src={navigate} />
          </div>
        </div>

        <Modal
          show={show}
          bsPrefix="modalt"
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>{languages['idioma'][idioma]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.formSubmitIdioma}>
              {radio}

              {this.state.selectedOptionIdioma ? (
                <div className="modal-center-blue">
                  <Button onClick={handleClose} type="submit">
                    {languages['confirmar'][idioma]}
                  </Button>
                </div>
              ) : (
                <div className="modal-center">
                  <Button onClick={handleClose}>{languages['cancela'][idioma]}</Button>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  ModalSettings() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const idioma = this.state.idioma;

    return (
      <>
        <img data-qa="settings-button" alt="" src={settingsSVG} className="add" onClick={handleShow} />

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title>{languages['definicoes'][idioma]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="radio3">
              <div className="idioma-settings">
                <this.ModalIdioma />
              </div>
            </div>
            <div className="modal-center">
              <Button onClick={handleClose}>{languages['cancela'][idioma]}</Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    const tickets = this.state.tickets;
    const idioma = this.state.idioma;

    return (
      <div className="queue--section container">
        <div className="top-header">
          <div className="top-settings">
            <div className="top-button-middle">
              <this.ModalSettings />
            </div>
          </div>
          <div className="top-title">{languages['minhasSenhas'][idioma]}</div>
          <div className="top-button">
            <div className="top-button-middle">
              <div className="Oval">
                <img data-qa="add-button" alt="" src={add} className="add" onClick={() => this.navigate(idioma)} />
              </div>
            </div>
          </div>
        </div>
        {tickets.length > 0 ? (
          <TicketMapper entries={this.state.tickets} entry={idioma} />
        ) : (
          <h1>{languages['vazio'][idioma]}</h1>
        )}
      </div>
    );
  }
}

export default Tickets;
