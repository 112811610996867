import React, { Component } from 'react';
import QrReader from 'react-qr-reader';

class QrCamera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: 'No result',
      name: '',
      showComponent: false
    };
  }

  handleScan = data => {
    const handleToUpdate = this.props.handleToUpdate;
    let ticketId;
    if (data) {
      try {
        const url = data.split('//')[1];
        ticketId = url.split('/')[2];
      } catch (e) {
        handleToUpdate('someVar');
      }
      this.setState({
        result: ticketId
      });
      this.handleAddTicket();
    }
  };
  handleError = err => {
    console.error(err);
  };

  canAddTicket() {
    if (this.state.result === 'No result') {
      return false;
    }
    const size = localStorage.getItem('@my-tickets-pwa/tickets-size');
    for (let i = 1; i <= size; i++) {
      let id = localStorage.getItem('@my-tickets-pwa/ticketId/' + i);
      if (id === this.state.result) {
        return false;
      }
    }
    return true;
  }

  async handleAddTicket() {
    const handleToUpdate = this.props.handleToUpdate;
    if (!(this.state.result === undefined)) {
      const size = localStorage.getItem('@my-tickets-pwa/tickets-size');

      if (size === null) {
        localStorage.setItem('@my-tickets-pwa/tickets-size', 1);
        localStorage.setItem('@my-tickets-pwa/ticketId/1', this.state.result);
      } else {
        if (this.canAddTicket()) {
          let newSize = parseInt(size) + 1;

          localStorage.setItem('@my-tickets-pwa/tickets-size', newSize);
          localStorage.setItem('@my-tickets-pwa/ticketId/' + newSize, this.state.result);
        } else {
          handleToUpdate('someVar');
        }
      }
      window.location.href = '/';
    }
  }

  render() {
    return (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%', height: '428px' }}
          className="testee"
        />
      </div>
    );
  }
}

export default QrCamera;
